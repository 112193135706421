import React from "react";
import "./Last.css";
import Github from "../../assets/github.png";
import linkdin from "../../assets/linkedin.png";
import instagram from "../../assets/instagram.png";
import logo from "../../assets/logo.png";
const Last = () => {
  return (
    <div className="footer" id="contacts">
    
      <hr />
      <div className="foot">
        <div className="social-links">
          <img src={Github} alt="" />
          <img src={linkdin} alt="" />
          <img src={instagram} alt="" />
        </div>

        <div className="logo">
          <img src={logo} alt="" />
        </div>
      </div>
      <div className="blur last-blur-1"></div>
      <div className="blur last-blur-2"></div>
    </div>
  );
};
export default Last;
