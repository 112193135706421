import React from "react";
import { useState } from "react";
import { testimonialsData } from "../../data/testimonialsData";
import "./Testimonial.css";
import rightArrow from "../../assets/rightArrow.png";
import Arrow from "../../assets/leftArrow.png";
import { motion } from "framer-motion";

const Testimonial = () => {
  const [selected, setSelected] = useState(0);
   const tLenght = testimonialsData.length;
  const transition = { type: "spring", duration: 3 };

  return (
    <div className="testimonial" id="testimonial">
      <div className="testimonial-l">
        <span>Testimonials</span>
        <span className="stroke-text">what they</span>
        <span>say about us</span>
        <motion.span
          key={selected}
          animate={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: -100 }}
          exit={{ opacity: 0, x: 100 }}
          transition={transition}
        >
          {testimonialsData[selected].review}
        </motion.span>
        <span>
          <span style={{ color: "var(--orange)" }}>
            {testimonialsData[selected].name}
          </span>{" "}
          -{testimonialsData[selected].status}
        </span>
      </div>

      <div className="testimonial-r">
        <div> </div>

        <div></div>

        <motion.img
          key={selected}
          animate={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: 100 }}
          exit={{ opacity: 0, x: -100 }}
          transition={transition}
          src={testimonialsData[selected].image}
          alt=""
        />
        <div className="arrow">
          <img
            onClick={() => {
              selected === 0
                ? setSelected(tLenght - 1)
                : setSelected((prev) => prev - 1);
            }}
            src={Arrow}
            alt=""
          />
          <img
            onClick={() => {
              selected === tLenght - 1
                ? setSelected(0)
                : setSelected((prev) => prev + 1);
            }}
            src={rightArrow}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
export default Testimonial;
