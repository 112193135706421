import "./App.css";
import Hero from "./components/Hero/Hero";
import Programs from "./components/Programs/Programs";
import Last from "./components/Last/Last";
import Plan from "./components/Plan/Plan";
import Join from "./components/Join/Join";
import Reason from "./components/Reasons/Reason";
import Testimonial from "./components/Testimonial/Testimonial";
function App() {
  return (
    <div className="App">
     <Hero />
     <Programs />
     <Reason />
     <Plan />
     <Testimonial/>
     <Join />
     <Last />

    </div>
  );
}

export default App;

